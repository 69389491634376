import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    Lugar,
    Lugares,
    Establecimientos
} from "@puntaje/puntaje/api-services"
import { AuthService, GenericModalComponent, SessionService, BaseForm } from "@puntaje/shared/core"

import { BaseRegisterCompletarComponent } from "./base_register_completar.component"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { UsuariosCompletarRegistroPuntajeForm } from "@puntaje/puntaje/api-services"
import { PreferenciaArea } from "@puntaje/carreras/api-services"

@Component({
    selector: "usuario-completar-register-sanisidoro",
    templateUrl: "register_completar_sanisidoro.component.html",
    styleUrls: ["base_register_completar.component.scss"]
})
export class RegisterCompletarSanisidoroComponent extends BaseRegisterCompletarComponent {
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()
    formType: typeof BaseForm = UsuariosCompletarRegistroPuntajeForm
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    identificadorUsuarioAlias: string
    enableEspecialidad = config.registro?.enableEspecialidad || false
    showUniversidades: boolean = config.registro?.completarRegistro?.enableUniversidades
    showAreas: boolean = config.registro?.completarRegistro?.enableAreas
    @Input() excludeCarreras = false

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        lugaresService: Lugares,
        establecimientosService: Establecimientos
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            lugaresService,
            establecimientosService
        )

        this.formType = UsuariosCompletarRegistroPuntajeForm
        this.params = this.formType.formParams

        this.usuarioPaisParams = this.params["usuario_" + this.pais].class.formParams

        if (!this.grupoUsuario || (this.grupoUsuario && !this.grupoUsuario.establecimiento)) {
            this.params.colegio.validations = null
            this.params.nivel_id.validations = null
        }
        if (this.pais.toLowerCase() == "mexico") {
            this.identificadorUsuarioAlias = config.plataforma.identificadorUsuarioAlias.numero_unico || "RUT"
        }
    }

    ngOnInit() {
        super.ngOnInit()
        if (this.excludeCarreras) {
            this.showUniversidades = false
            this.showAreas = false
            this.params.area_id.validations = null
            this.form.get("area_id").clearValidators()
        }
    }

    checkTelefono() {
        const telefono = this.form.value["usuario_" + this.pais].telefono
        if (!telefono && config.plataforma.name == "Mineduc") {
            this.openModal.next()
        } else {
            this.save()
        }
    }

    goOmitir() {
        this.genericModal.close()
        this.save()
    }

    updatePreferencias(preferencias) {
        this.usuario.preferencia_universidades = preferencias
    }

    updatePreferenciaArea(areaId) {
        const preferenciaArea = new PreferenciaArea()
        preferenciaArea.areaId = areaId
        this.usuario.preferencia_areas = [preferenciaArea]
    }
}
