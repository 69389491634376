import { PuntajeEnsayosModule, RealizarEvaluacionMultipleComponent } from "@puntaje/puntaje/core"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { UtilModule } from "@puntaje/shared/core"
import { TranslationsModule } from "@puntaje/shared/core"
import { routing } from "./alumnos_ensayos.routing"
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"

import { HomePruebasComponent } from "./home_pruebas.component"
import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"

import { CUIContainersModule, CUILayoutsModule } from "@puntaje/shared/cui"
import { EnsayosRecomendadosComponent } from "./ensayos_recomendados.component"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        AlumnosLayoutsModule,
        SharedModule,
        routing,
        Angular2FontawesomeModule,
        UtilModule,
        NebuModule,
        TranslationsModule,
        CUIContainersModule,
        CUILayoutsModule,
        PuntajeEnsayosModule,
        InstrumentosModule
    ],
    declarations: [HomeSaberComponent, HomeSaber11Component, HomePruebasComponent, EnsayosRecomendadosComponent],
    exports: [],
    providers: [
        {
            provide: "canExitEvaluacion",
            useValue: (
                component: RealizarEvaluacionMultipleComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState: RouterStateSnapshot
            ) => {
                if (!component.canGoAway) {
                    return window.confirm("¿Está seguro de querer salir de la evaluación?")
                }

                return true
            }
        }
    ]
})
export class AlumnosEnsayosModule {}
